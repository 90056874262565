// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_leaderboard__sYj5t {
  width: 100%;
  max-width: 350px;
  padding-bottom: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.style_toggle__Sur9r {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  max-width: 350px;
  width: 100%;
}

.style_toggle__option__vLTsv {
  flex: 1 1;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 1.4rem;
  background: rgb(213, 212, 220);
  line-height: 18px;
  color: rgb(27, 27, 27);
  font-weight: 700;
  text-transform: uppercase;
}

.style_toggle__option__active__Use-8 {
  background-color: #4caf50;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/features/clans/clan/components/leaderboard/style.module.scss","webpack://./public/assets/colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AADF;;AAIA;EACE,SAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;EACA,8BAAA;EACA,iBAAA;EACA,sBC9BY;ED+BZ,gBAAA;EACA,yBAAA;AADF;;AAIA;EACE,yBAAA;EACA,YAAA;AADF","sourcesContent":["@import 'public/assets/colors';\n\n.leaderboard {\n  width: 100%;\n  max-width: 350px;\n  padding-bottom: 30px;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n}\n\n.toggle {\n  display: flex;\n  border-radius: 20px;\n  overflow: hidden;\n  max-width: 350px;\n  width: 100%;\n}\n\n.toggle__option {\n  flex: 1;\n  border: none;\n  outline: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n  font-size: 1.4rem;\n  background: rgba(213, 212, 220, 1);\n  line-height: 18px;\n  color: $black-extra;\n  font-weight: 700;\n  text-transform: uppercase;\n}\n\n.toggle__option__active {\n  background-color: #4caf50;\n  color: white;\n}\n","$main: rgba(117, 83, 240, 1);\n$light-main: rgba(237, 235, 254, 1);\n$black-extra: rgba(27, 27, 27, 1);\n$dark-grey: rgba(135, 135, 135, 1);\n$light-background: rgba(247, 246, 255, 1);\n$white: rgba(255, 255, 255, 1);\n$light-grey: rgba(213, 212, 220, 1);\n$yellow: rgba(255, 199, 1, 1);\n$grey: rgba(158, 158, 158, 1);\n$light-green: #39b283;\n$green: #1f6b4e;\n$dark-green: #1f4c3a;\n$danger: #c10000;\n$red: #e73232;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leaderboard": `style_leaderboard__sYj5t`,
	"toggle": `style_toggle__Sur9r`,
	"toggle__option": `style_toggle__option__vLTsv`,
	"toggle__option__active": `style_toggle__option__active__Use-8`
};
export default ___CSS_LOADER_EXPORT___;
