import React, { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Profile from './pages/profile';
import Earn from './pages/earn';
import TelegramOnly from './pages/telegram-only';
import Boosts from './features/boosts';
import Challenge from './pages/challenge';
import ROUTES from './utils/routes';
import { useLoginMutation } from './services/auth/authApi';
import { setCredentials } from './services/auth/authSlice';
import AuthProtectedRoute from './components/auth-protected-route';
import { RegisterPayload, useRegisterMutation } from './services/user/userApi';
import { LoginPayload } from './services/auth/types';
import Email from './features/email';
import { setInvitation } from './services/achievement/achievementSlice';
import TelegramBackButtonHandler from './components/telegram-back-button';
import { showLoader } from './services/loader/loaderSlice';
import RestrictedDevice from './pages/restricted-device';
import Clan from './features/clans/clan';
import ClanSelection from './features/clans/clan-selection';
import ReferralsDashboard from './features/referrals/referrals-dashboard';
import ReferralsList from './features/referrals/referrals-list';
import Tower from './features/tower';
import ClanWars from './features/clans-war';
import { GtagEventType, trackGtagEvent } from './utils/gtagEventHandler';
import ScrollProvider from './components/scroll-provider';

const App = () => {
  const [telegramUser, setTelegramUser] = useState<TelegramUser | null>(null);
  const [initDataUnsafe, setInitDataUnsafe] =
    useState<TelegramInitDataUnsafe | null>(null);
  const dispatch = useDispatch();

  const checkDevice = () => {
    if (window.Telegram?.WebApp?.platform) {
      const platform = window.Telegram.WebApp.platform;
      if (
        platform === 'android' ||
        (platform === 'ios' &&
          Math.min(window.screen.width, window.screen.height) < 768)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const validDevice = checkDevice();

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();
      if (window.Telegram.WebApp.initDataUnsafe) {
        const initData = window.Telegram.WebApp
          .initDataUnsafe as TelegramInitDataUnsafe;
        setInitDataUnsafe(initData);
        setTelegramUser(initData.user);
        window.Telegram.WebApp.disableVerticalSwipes();
      }
    }
  }, []);

  useEffect(() => {
    if (validDevice) {
      dispatch(showLoader());
    }
  }, [validDevice, dispatch]);

  useEffect(() => {
    if (telegramUser) {
      window.Telegram.WebApp.expand();
    }
  }, [telegramUser]);

  const [
    login,
    { data: loginData, isLoading: isLoginLoading, isError: isLoginError },
  ] = useLoginMutation();

  const [register, { data: registerData }] = useRegisterMutation();
  const hasLoggedIn = useRef(false);

  useEffect(() => {
    if (validDevice && registerData && initDataUnsafe) {
      const loginPayload: LoginPayload = {
        initDataUnsafe: initDataUnsafe,
      };

      login(loginPayload);

      hasLoggedIn.current = true;
    }
  }, [validDevice, registerData, login, initDataUnsafe]);

  useEffect(() => {
    if (validDevice && !hasLoggedIn.current && initDataUnsafe) {
      const loginPayload: LoginPayload = {
        initDataUnsafe: initDataUnsafe,
      };
      let startParam = initDataUnsafe.start_param;

      if (startParam && startParam.toLowerCase().includes('bump')) {
        trackGtagEvent(GtagEventType.BUMP);
        startParam = '';
      }
      if (startParam && startParam.toLocaleLowerCase().includes('qappi')) {
        trackGtagEvent(GtagEventType.QAPPI);
        startParam = '';
      }
      if (startParam && startParam.toLocaleLowerCase().includes('cryptonic')) {
        trackGtagEvent(GtagEventType.CRYPTO_NIC);
        startParam = '';
      }
      if (startParam && startParam.toLocaleLowerCase().includes('jupbot')) {
        trackGtagEvent(GtagEventType.JUPBOT);
        startParam = '';
      }
      if (startParam && startParam.toLocaleLowerCase().includes('cptc')) {
        trackGtagEvent(GtagEventType.CPTC);
        startParam = '';
      }
      if (startParam && startParam.toLocaleLowerCase().includes('temaultra')) {
        trackGtagEvent(GtagEventType.TEMA_ULTRA);
        startParam = '';
      }
      if (startParam && startParam.toLocaleLowerCase().includes('bitbot')) {
        trackGtagEvent(GtagEventType.BitBot);
        startParam = '';
      }
      if (startParam && startParam.toLocaleLowerCase().includes('teafarm')) {
        trackGtagEvent(GtagEventType.TeaFarm);
        startParam = '';
      }
      if (startParam && startParam.toLocaleLowerCase().includes('swopin')) {
        trackGtagEvent(GtagEventType.Swopin);
        startParam = '';
      }
      if (startParam && startParam.toLocaleLowerCase().includes('bcoin')) {
        trackGtagEvent(GtagEventType.Bcoin);
        startParam = '';
      }
      login(loginPayload);

      hasLoggedIn.current = true;
    }
  }, [validDevice, login, isLoginLoading, initDataUnsafe]);

  useEffect(() => {
    if (validDevice && isLoginError && initDataUnsafe) {
      if (initDataUnsafe.user) {
        const telegramId = initDataUnsafe.user.id;
        const telegramUsername = initDataUnsafe.user.username;
        let startParam = initDataUnsafe.start_param;

        if (startParam && startParam.toLowerCase().includes('bump')) {
          startParam = '';
        }
        if (startParam && startParam.toLocaleLowerCase().includes('qappi')) {
          startParam = '';
        }
        if (
          startParam &&
          startParam.toLocaleLowerCase().includes('cryptonic')
        ) {
          startParam = '';
        }
        if (startParam && startParam.toLocaleLowerCase().includes('jupbot')) {
          startParam = '';
        }
        if (startParam && startParam.toLocaleLowerCase().includes('cptc')) {
          startParam = '';
        }
        if (
          startParam &&
          startParam.toLocaleLowerCase().includes('temaultra')
        ) {
          startParam = '';
        }
        if (startParam && startParam.toLocaleLowerCase().includes('bitbot')) {
          startParam = '';
        }
        if (startParam && startParam.toLocaleLowerCase().includes('teafarm')) {
          startParam = '';
        }
        if (startParam && startParam.toLocaleLowerCase().includes('swopin')) {
          startParam = '';
        }
        if (startParam && startParam.toLocaleLowerCase().includes('bcoin')) {
          startParam = '';
        }
        const registerPayload: RegisterPayload = {
          telegramId: telegramId,
          telegramUsername: telegramUsername,
          referralCode: startParam,
        };

        if (startParam) {
          dispatch(setInvitation(true));
        }

        register(registerPayload);
      }
    }
  }, [validDevice && isLoginError, register, initDataUnsafe, dispatch]);

  useEffect(() => {
    if (validDevice && loginData) {
      dispatch(
        setCredentials({
          user: loginData.user,
          accessToken: loginData.accessToken,
          refreshToken: loginData.refreshToken,
        }),
      );
    }
  }, [validDevice && loginData, dispatch]);

  if (telegramUser === null) {
    return null;
  }

  if (!telegramUser) {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<Navigate to={ROUTES.TELEGRAM_ONLY} />} />
          <Route path={ROUTES.TELEGRAM_ONLY} element={<TelegramOnly />} />
        </Routes>
      </Router>
    );
  }

  if (!validDevice) {
    return (
      <Router>
        <Routes>
          <Route
            path="*"
            element={<Navigate to={ROUTES.RESTRICTED_DEVICE} />}
          />
          <Route
            path={ROUTES.RESTRICTED_DEVICE}
            element={<RestrictedDevice />}
          />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <ScrollProvider>
        <TelegramBackButtonHandler />
        <Routes>
          <Route
            path={ROUTES.HOME}
            element={<AuthProtectedRoute element={<Tower />} />}
          />
          <Route
            path={ROUTES.BOOSTS}
            element={<AuthProtectedRoute element={<Boosts />} />}
          />
          <Route
            path={ROUTES.CHALLENGE}
            element={<AuthProtectedRoute element={<Challenge />} />}
          />
          <Route
            path={ROUTES.EARN}
            element={<AuthProtectedRoute element={<Earn />} />}
          />
          <Route
            path={ROUTES.PROFILE}
            element={<AuthProtectedRoute element={<Profile />} />}
          />
          <Route
            path={ROUTES.REFERRAL}
            element={<AuthProtectedRoute element={<ReferralsDashboard />} />}
          />
          <Route
            path={ROUTES.REFERRAL_ALL}
            element={<AuthProtectedRoute element={<ReferralsList />} />}
          />
          <Route
            path={ROUTES.EMAIL}
            element={<AuthProtectedRoute element={<Email />} />}
          />
          <Route
            path="/clan/:id"
            element={<AuthProtectedRoute element={<Clan />} />}
          />
          <Route
            path={ROUTES.JOIN_CLAN}
            element={<AuthProtectedRoute element={<ClanSelection />} />}
          />
          <Route
            path={ROUTES.CLAN_WARS}
            element={<AuthProtectedRoute element={<ClanWars />} />}
          />
          <Route path={ROUTES.TELEGRAM_ONLY} element={<TelegramOnly />} />
        </Routes>
      </ScrollProvider>
    </Router>
  );
};

export default App;
