import { ReactNode } from 'react';
import classes from './style.module.scss';
import CoinBadgeImage from '../../../../assets/images/coin-m.svg';
import clsx from 'clsx';
import { formatCoins } from '../../../../utils/utils';

interface TaskItemProps {
  icon: string;
  title: string;
  reward: number;
  children: ReactNode;
  isCompleted?: boolean;
  filled?: boolean;
  isPartner?: boolean;
}
const TaskItem = ({
  icon,
  title,
  reward,
  children,
  isCompleted,
  filled,
  isPartner,
}: TaskItemProps) => {
  return (
    <div
      className={clsx(classes.task, {
        [classes.task__filled]: filled,
      })}
    >
      <div className={classes.task__left}>
        {isPartner ? (
          <div>
            <img
              style={{ width: '32px', height: '32px' }}
              src={`${process.env.REACT_APP_API_BASE_URL}/images/${icon}`}
              alt=""
            />
          </div>
        ) : (
          <i
            className={clsx(`${classes.task__icon} icon-${icon}`, {
              [classes.task__icon__filled]: filled,
            })}
          />
        )}
        <div className={classes.task__content}>
          <p
            className={clsx(classes.task__content__title, {
              [classes.faded]: isCompleted,
              [classes.white]: filled,
            })}
          >
            {title}
          </p>
          <p
            className={clsx(classes.task__content__subtitle, {
              [classes.faded]: isCompleted,
              [classes.white]: filled,
            })}
          >
            <img src={CoinBadgeImage} alt="coin icon" />
            {formatCoins(reward)}
          </p>
        </div>
      </div>
      <div className={classes.task__right}>{children}</div>
    </div>
  );
};

export default TaskItem;
